import { Box, IconButton, InputAdornment, Typography } from '@mui/material'
import { StyledTextBox } from '../../../../shared/styledComponents/StyledTextBox/StyledTextBox'
import { StyledLoadingButton } from '../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import {
	copyToClipboard,
	showSuccessToast,
	timeout,
} from '../../../../../utils/helperFunctions/helperFunctions'
import { v4 as uuidv4 } from 'uuid'

const CustomerAPIKeySection = ({
	customerAPIKey,
	addCustomerAccessKey,
	deleteCustomerAccessKey,
}: {
	customerAPIKey: string
	addCustomerAccessKey: (key: string) => Promise<void>
	deleteCustomerAccessKey: () => Promise<void>
}) => {
	// Display constant
	const [keyValue, setKeyValue] = useState('')

	// Logic constants
	const [showAccessKey, setShowAccessKey] = useState(false)
	const [keyCopied, setKeyCopied] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setKeyValue(customerAPIKey)
	}, [customerAPIKey])

	// Handle Functions
	// Generate key - Using a uuid to ensure no issues of duplication
	const generateAccessKey = (): string => {
		const uuid = uuidv4().replace(/-/g, '')
		let key = uuid.substring(0, 32)

		// Add two random dashes
		const numDashes = 2
		for (let i = 0; i < numDashes; i++) {
			const dashIndex = Math.floor(Math.random() * (key.length + 1))
			key = key.slice(0, dashIndex) + '-' + key.slice(dashIndex)
		}

		// Append '=='
		return key + '=='
	}

	// Handle generation of access key
	const handleAccessKeyAction = async () => {
		setLoading(true)

		// Check if add or delete
		if (keyValue.length > 0) {
			// Delete key
			await deleteCustomerAccessKey()
			setShowAccessKey(false)
		} else {
			// Generate key then add to the DB
			var accessKey = generateAccessKey()
			if (accessKey) {
				await addCustomerAccessKey(accessKey)
			}
		}

		// Stop loading
		setLoading(false)
	}

	// Handle copy to clipboard
	const handleCopyToClipboard = async () => {
		await copyToClipboard(keyValue)
		setKeyCopied(true)
		showSuccessToast('Access key copied to clipboard.')
		await timeout(2000)
		setKeyCopied(false)
	}

	return (
		<>
			<Box className='s-form-section'>
				{/* Label */}
				<Box className='s-text'>
					<Typography component='h4'>API Access:</Typography>
					{keyValue.length > 0 ? (
						<Typography sx={{ color: '#808080' }} component='p'>
							Copy your API key to access the Customer HALO API. Click on the
							button to delete your access key. For API documentation, please
							refer to the{' '}
							<a
								href={process.env.REACT_APP_CHAPI_SWAGGER_LINK + ''}
								target='_blank'
								rel='noreferrer'>
								Swagger
							</a>
							.
						</Typography>
					) : (
						<Typography sx={{ color: '#808080' }} component='p'>
							Click on the button to get API access. A key will be generated and
							stored.
						</Typography>
					)}
				</Box>
				{/* Key Textfield */}
				<Box className='s-form-field'>
					{/* Textfield */}
					<StyledTextBox
						disabled
						fullWidth
						type={showAccessKey ? 'text' : 'password'}
						placeholder='API Access Key'
						value={keyValue}
						InputProps={{
							endAdornment: keyValue.length > 0 && (
								<InputAdornment position='end'>
									<IconButton
										aria-label={
											showAccessKey ? 'Hide access key' : 'Show access key'
										}
										onClick={() => setShowAccessKey((show) => !show)}>
										{showAccessKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
									</IconButton>
									<IconButton
										disabled={keyCopied}
										aria-label='Copy access key'
										onClick={handleCopyToClipboard}>
										{keyCopied ? <DoneIcon /> : <ContentCopyIcon />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{/* Button */}
					<Box className='s-btn'>
						<StyledLoadingButton
							className={keyValue.length > 0 ? 'del' : 'add'}
							disabled={loading}
							variant='contained'
							onClick={handleAccessKeyAction}>
							{keyValue.length > 0
								? 'Delete Access Key'
								: 'Generate Access Key'}
						</StyledLoadingButton>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default CustomerAPIKeySection
